import React, { useEffect, useState } from "react";
import '../styles/miniCart.css'
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getCartCount } from "../features/cartProductCountSlice";
import { ShimmerButton, ShimmerSectionHeader, ShimmerThumbnail } from "react-shimmer-effects";
import CheckoutButton from "../components/checkoutButton";
import { Offcanvas } from "react-bootstrap";
import ConfirmationModal from "./modals/ConfirmationModal";
import Loader from "./modals/loader";
import { toast } from "react-toastify";
import { useAddProductMutation, useCartItemsMutation, useRemoveCartProductMutation } from "../features/apiSlice";

const MiniCart = (props) => {
    const { cartData, open, setOpen } = props
    const [cartProducts, setCartProducts] = useState({})
    const [quantity, setQuan] = useState(0)
    const [count, setCount] = useState()
    const [cartLoader, setCartLoader] = useState({ cart: false, updateLoader: false, checkoutLoader: true });
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [showPopup, setShowPopup] = useState({});
    const [cartItems] = useCartItemsMutation();
    const [removeCartProduct] = useRemoveCartProductMutation();
    const [addProduct] = useAddProductMutation();

    const AddToCart = async (obj) => {
        
        try {
            const response = await addProduct({
                "items": [obj]
            }).unwrap();
     
            !response[0]?.status ?
              (
                toast(response[0].message, { type: "error" })
              )
              : dispatch(getCartCount({ cart: response[0]?.cart, count: response[0]?.count }))
      
          } catch (err) {
            toast(err?.data?.message || "Something went wrong! Please try again.", { type: "error" });
          }

       
        setCartLoader({ ...cartLoader, updateLoader: false })
       
      }

    const removeCartProducts = async (params) => {
        try {
            const response = await removeCartProduct(params).unwrap();
            setCartLoader({ ...cartLoader, updateLoader: false });
            response && response[0]?.success ? dispatch(getCartCount({ cart: response[0]?.cart, count: response[0]?.count })) : ""

        } catch (err) {
            console.error('Failed to remove cart item: ', err);
            setCartLoader({ ...cartLoader, updateLoader: false });
        }

    }

    useEffect(() => {

        const init = async () => {
            try {
                setCartLoader({...cartLoader,cart: true})
                const response = await cartItems({ minicart: false }).unwrap();

                response[0]?.status ?
                    (setCount(response[0]?.count),
                        dispatch(getCartCount({ cart: response[0], count: response[0]?.count })),
                        setQuan(response[0]?.products),
                        setCartProducts(response[0])
                    ) : dispatch(getCartCount({ cart: {}, count: 0 }));

                    setCartLoader({...cartLoader,cart: false})

            } catch (error) {
                toast(error?.message ? error?.message : "Request failed! Please try again.", { type: "error" })
                setCartLoader({...cartLoader,cart: false})
            }

        }


        if (open && (cartData?.cart && Object.keys(cartData?.cart)?.length == 0 && cartData?.count) > 0) {
            init()
        }
        else if ((cartData?.cart && Object.keys(cartData?.cart)?.length > 0)) {
            setCartProducts(cartData?.cart)
            setQuan(cartData?.cart?.products)
            setCount(cartData?.count)
        }

    }, [open, cartData])


    const handleConfirm = async () => {
       
        setShowPopup({ ...showPopup, isOpen: false })
        setCartLoader({ ...cartLoader, updateLoader: true });


        removeCartProducts({
            productIds: [showPopup.pid], "is_warranty": false,
            "main_product_id": null
        });
       

    }


    const updateProductWarranty = async (warrantyProd, Prod, checked) => {
        setCartLoader({ ...cartLoader, updateLoader: true })
        const wrntP = quantity?.filter((main) => { return main.product_id == Prod })[0]?.warranty_products
        const MainQuan = quantity?.filter((main) => { return main.product_id == Prod })[0].quantity
        if (checked) {
            setQuan([...quantity?.filter((q) => { return q.product_id != Prod }),
            {
                "product_id": quantity?.filter((main) => { return main.product_id == Prod })[0].product_id,
                "quantity": MainQuan,
                "fixed_warranty": quantity?.filter((main) => { return main.product_id == Prod })[0]?.fixed_warranty,
                "warranty_products": wrntP?.length > 0 ? [...wrntP?.filter((w) => { return w.product_id != warrantyProd }).map(({ product_id, quantity }) => ({ product_id, quantity })), { "product_id": warrantyProd, "quantity": 1 }] : [{ "product_id": warrantyProd, "quantity": 1 }]
            }
            ])

            AddToCart({
                "product_id": quantity?.filter((main) => { return main.product_id == Prod })[0].product_id,
                "quantity": MainQuan,
                "warranty_products": wrntP?.length > 0 ? [...wrntP?.filter((w) => { return w.product_id != warrantyProd }).map(({ product_id, quantity }) => ({ product_id, quantity })), { "product_id": warrantyProd, "quantity": 1 }] : [{ "product_id": warrantyProd, "quantity": 1 }]
            })

        } else {
            handleRemove(Prod, warrantyProd, "")
        }
    }

    const updateCart = async (quantity1, productID, info) => {
        setCartLoader({ ...cartLoader, updateLoader: true })
        setQuan([...quantity?.filter((q) => { return q.product_id != productID }),
        {
            "product_id": quantity?.filter((main) => { return main.product_id == productID })[0].product_id,
            "quantity": quantity1,
            "fixed_warranty": quantity?.filter((main) => { return main.product_id == productID })[0]?.fixed_warranty,
            "warranty_products": quantity?.filter((main) => { return main.product_id == productID })[0]?.warranty_products.map(({ product_id, quantity }) => ({ product_id, quantity }))
        }
        ]);
       

        AddToCart({
            "product_id": quantity?.filter((main) => { return main.product_id == productID })[0].product_id,
            "quantity": quantity1,
            "warranty_products": quantity?.filter((main) => { return main.product_id == productID })[0]?.warranty_products.map(({ product_id, quantity }) => ({ product_id, quantity }))
        })

    }

    const handleRemove = async (pid, wid, info) => {

        if (wid) {
            setCartLoader({ ...cartLoader, updateLoader: true })
            removeCartProducts({
                productIds: [wid], "is_warranty": true,
                "main_product_id": pid
            });
            setQuan([...quantity?.filter((q) => { return q.product_id != pid }),
            {
                "product_id": quantity?.filter((main) => { return main.product_id == pid })[0].product_id,
                "quantity": quantity?.filter((main) => { return main.product_id == pid })[0].quantity,
                "fixed_warranty": quantity?.filter((main) => { return main.product_id == pid })[0]?.fixed_warranty,

                "warranty_products": quantity?.filter((main) => { return main.product_id == pid })[0]?.warranty_products?.length > 0 ? [...quantity?.filter((main) => { return main.product_id == pid })[0]?.warranty_products?.filter((w) => { return w.product_id != wid }).map(({ product_id, quantity }) => ({ product_id, quantity }))] : []
            }
            ])
        }
        else {
           
            setShowPopup({ isOpen: true, pid: pid, info: info })
        }
    }


    return (
        <div >

            <div className="minicart-popup">

                {cartData?.count > 0 ?
                    <>

                        {!cartLoader.cart ?
                            <Offcanvas show={open} placement="end" className="minicart-popup" onHide={() => setOpen(false)}>
                                <div className="header-bottom-border" >
                                    <Offcanvas.Header className=" mini-cart-content d-flex justify-content-between align-items-center">

                                        <h5 id="offcanvasRightLabel">Cart ({count} items)</h5>
                                        <button type="button" onClick={() => { setOpen(false) }} className="btn-close text-reset" data-bs-dismiss="offcanvas"
                                            aria-label="Close"></button>
                                    </Offcanvas.Header></div>
                                <Offcanvas.Body>
                                   
                                    {cartLoader?.updateLoader ? <Loader /> : ""}

                                    <div className="mini-sub-total-area">


                                        {cartProducts?.products?.map((k, _ik) => {
                                            
                                            const qnt = quantity?.filter((q1) => { return q1.product_id == k.product_id })[0]
                                            return <div className="row sub-total-mini-items d-flex" key={_ik} >
                                                <div className="col-lg-2 col-md-2 col-sm-12">
                                                    <div className="minicart-select-left-images" role={"button"} onClick={() => navigate(`/${k.sku.toLowerCase()}`)}>
                                                        <img src={k.image} alt="cart-img" title="cart-img" width={65} height={65} />
                                                    </div>
                                                </div>
                                                <div className="col-lg-10 col-md-10 col-sm-12 minicart-select-left-content">

                                                    <div className="minicart-select-left-heading">
                                                        <h3 role={"button"} onClick={() => navigate(`/${k.sku.toLowerCase()}`)}>{k.name}</h3>
                                                        <p>{k.color}</p>
                                                        <h4>${k.price ? k.price : "0.00"}</h4>
                                                    </div>
                                                    <div className="cart-value-define vart d-grid align-items-start align-items-center ">
                                                        <div className="d-flex gap-3">
                                                            <div
                                                                className="cart-value-increse-decrease carts d-flex justify-content-between align-items-center">
                                                                <button type="button" onClick={() => { (parseInt(qnt.quantity) - 1) > 0 ? updateCart(parseInt(qnt.quantity) - 1, k.product_id, k) : "" }}
                                                                    className="cart-value-decrease-btn d-flex align-items-center justify-content-center">
                                                                    <svg width="9" height="2" viewBox="0 0 9 2" fill="none"
                                                                        xmlns="http://www.w3.org/2000/svg">
                                                                        <path
                                                                            d="M0.638021 1.2207C0.453926 1.2207 0.304688 1.01681 0.304688 0.765273C0.304688 0.513739 0.453926 0.309843 0.638021 0.309843H7.97135C8.15545 0.309843 8.30469 0.513739 8.30469 0.765273C8.30469 1.01681 8.15545 1.2207 7.97135 1.2207H0.638021Z"
                                                                            fill="#1D1D1B"></path>
                                                                    </svg>
                                                                </button>
                                                                <div
                                                                    className="cart-value-text-area d-flex align-items-center justify-content-center">
                                                                    <input type="text" value={quantity?.length > 0 ? qnt.quantity : pro.quantity}
                                                                        onChange={({ target: { value } }) => { (value > 0 ? updateCart(value, k.product_id, k) : "") }}
                                                                    />
                                                                </div>
                                                                <button type="button" onClick={() => { (updateCart(parseInt(qnt.quantity) + 1, k.product_id, k)) }}
                                                                    className="cart-value-increase-btn d-flex align-items-center justify-content-center">
                                                                    <svg width="10" height="10" viewBox="0 0 10 10" fill="none"
                                                                        xmlns="http://www.w3.org/2000/svg">
                                                                        <path
                                                                            d="M8.875 4.76514C8.875 4.93369 8.74721 5.07035 8.58929 5.07035H5.16071V8.73289C5.16071 8.90144 5.03292 9.0381 4.875 9.0381C4.71708 9.0381 4.58929 8.90144 4.58929 8.73289V5.07035H1.16071C1.00279 5.07035 0.875 4.93369 0.875 4.76514C0.875 4.59659 1.00279 4.45993 1.16071 4.45993H4.58929V0.797399C4.58929 0.628847 4.71708 0.492188 4.875 0.492188C5.03292 0.492188 5.16071 0.628847 5.16071 0.797399V4.45993H8.58929C8.74721 4.45993 8.875 4.59659 8.875 4.76514Z"
                                                                            fill="#1D1D1B" stroke="#1D1D1B" strokeWidth="0.3">
                                                                        </path>
                                                                    </svg>
                                                                </button>
                                                            </div>
                                                            <div
                                                                className="cart-value-close d-flex align-items-center justify-content-center">
                                                                <button type="button" title="Remove Item" onClick={() => handleRemove(k?.product_id, "", k)}
                                                                    className="cart-close-btn d-flex align-items-center h-100">
                                                                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none"
                                                                        xmlns="http://www.w3.org/2000/svg">
                                                                        <path
                                                                            d="M11.0859 10.7651L1.08594 0.765137M11.0859 0.765137L1.08594 10.7651"
                                                                            stroke="#1D1D1B" strokeLinecap="round"
                                                                            strokeLinejoin="round"></path>
                                                                    </svg>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div className="cart-select-left-delivery-options m-0" >

                                                           {qnt?.fixed_warranty?.length > 0 ? <h2 className="deliveries-extend ">CPS Extended Warranty Plans:</h2>  : ""} 
                                                            <div className="checked-extend-order-cps">
                                                                {qnt?.fixed_warranty?.map((fw, _ifw) => {
                                                                    return <div className="cps-by d-flex " key={_ifw}>
                                                                        <input className="form-check-input" type="checkbox"
                                                                            id={`warrantyCps${_ik}${_ifw}`} onChange={({ target: { checked } }) => {
                                                                                updateProductWarranty(fw.product_id, k.product_id, checked)
                                                                            }} checked={qnt?.warranty_products?.some(obj => obj.product_id == fw.product_id)} />
                                                                        <label className="form-check-label" htmlFor={`warrantyCps${_ik}${_ifw}`}>{fw.name} <span className="rate-highlight">${`${fw.price}`}</span> </label>
                                                                    </div>
                                                                })}

                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        })}





                                        <div className="mini-credit-card">
                                            <h2>Credit cards Available</h2>
                                            <div className="row ">
                                                <div className="col-lg-10">
                                                    <div className="row mini-credits">
                                                        <div className="col-2">
                                                            <img src={`${process.env.REACT_APP_AmazonLink}/header/card1.png`} alt="cards" title="cards" />
                                                        </div>
                                                        <div className="col-2">
                                                            <img src={`${process.env.REACT_APP_AmazonLink}/header/card2.png`} alt="cards" title="cards" />
                                                        </div>
                                                        <div className="col-2">
                                                            <img src={`${process.env.REACT_APP_AmazonLink}/header/card3.png`} alt="cards" title="cards" />
                                                        </div>
                                                        <div className="col-2">
                                                            <img src={`${process.env.REACT_APP_AmazonLink}/header/card4.png`} alt="cards" title="cards" />
                                                        </div>
                                                        <div className="col-2">
                                                            <img src={`${process.env.REACT_APP_AmazonLink}/header/card5.png`} alt="cards" title="cards" />
                                                        </div>
                                                        <div className="col-2">
                                                            <img src={`${process.env.REACT_APP_AmazonLink}/header/card6.png`} alt="cards" title="cards" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2"></div>
                                            </div>
                                        </div>

                                        <div className="mini-easy-finacing">
                                            <div
                                                className="mini-easy-finacing-head d-flex justify-content-between align-items-center">
                                                <h2>easy Financing offers with</h2>
                                                {/* <p>As low as $144.10/mo*</p> */}
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-4 col-md-4 col-4">
                                                    <div className="mini-finacing-logo">
                                                        <img src={`${process.env.REACT_APP_AmazonLink}/header/min-finace-1.png`} alt="finacing-logo" title="finacing-logo" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-4">
                                                    <div className="mini-finacing-logo">
                                                        <img src={`${process.env.REACT_APP_AmazonLink}/header/min-finace-2.png`} alt="finacing-logo" title="finacing-logo" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-4">
                                                    <div className="mini-finacing-logo">
                                                        <img src={`${process.env.REACT_APP_AmazonLink}/header/min-finace-3.png`} alt="finacing-logo" title="finacing-logo" />
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="cart-sub-bottom">
                                            <div className="mini-cartsub-total-top d-flex justify-content-between align-items-center">
                                                <h2>Subtotal</h2>
                                                <h2>${cartProducts?.subtotal}</h2>
                                            </div>
                                            {cartProducts?.discounted_amount ? <div className="mini-cartsub-total-top d-flex justify-content-between align-items-center">
                                                <h2>Discount</h2>
                                                <h2>-${cartProducts?.discounted_amount}</h2>
                                            </div> : ""}
                                            {cartProducts?.estimated_total ? <div className="mini-cartsub-total-top d-flex justify-content-between align-items-center">
                                                <h2>Estimated Total</h2>
                                                <h2>${cartProducts?.estimated_total}</h2>
                                            </div> : ""}
                                        </div>
                                        <div className="pb-5" >
                                        <div className="mini-cart-button mb-3">
                                            <Link to="/checkout/cart" title="goto-cart"> GO TO CART</Link>
                                        </div>
                                        <CheckoutButton loader={false} page={"minicart"} checkoutLink={`${process.env.REACT_APP_MAGENTO_BASE_URL}/quote/cart/load?quote_id=${cartProducts?.quoteId}`} />
                                        </div>


                                    </div></Offcanvas.Body>
                            </Offcanvas> :
                            <Offcanvas show={open ? true : false} placement="end" onHide={() => setOpen(false)}>

                                <Offcanvas.Body>
                                    <div className="header-bottom-border">
                                        <div className="offcanvas-header mini-cart-content d-flex justify-content-between align-items-center">
                                            <h5 id="offcanvasRightLabel">Cart </h5>
                                            <button type="button" onClick={() => { setOpen(false) }} className="btn-close text-reset" data-bs-dismiss="offcanvas"
                                                aria-label="Close"></button>
                                        </div>
                                        {/* <div className="mini-cartsub-total-top d-flex justify-content-between align-items-center">
                                        <h2>Subtotal</h2>
                                        <h6>...</h6>
                                    </div> */}


                                    </div>
                                    <div className="offcanvas-body mini-sub-total-area">


                                        <div className="row sub-total-mini-items d-flex">
                                            <div className="col-lg-2 col-md-2 col-sm-12">

                                                <ShimmerThumbnail height={80} />

                                            </div>
                                            <div className="col-lg-10 col-md-10 col-sm-12 minicart-select-left-content">


                                                <ShimmerSectionHeader width={100} />


                                                <div className="cart-value-define vart d-flex align-items-start align-items-center">
                                                    <ShimmerButton />

                                                </div>

                                            </div>
                                        </div>

                                        <div className="row sub-total-mini-items d-flex">
                                            <div className="col-lg-2 col-md-2 col-sm-12">
                                                <div className="minicart-select-left-images">
                                                    <ShimmerThumbnail height={80} />
                                                </div>
                                            </div>
                                            <div className="col-lg-10 col-md-10 col-sm-12 minicart-select-left-content">

                                                <div className="minicart-select-left-heading">
                                                    <ShimmerSectionHeader width={100} />

                                                </div>
                                                <div className="cart-value-define vart d-flex align-items-start align-items-center">
                                                    <ShimmerButton />

                                                </div>

                                            </div>
                                        </div>







                                        <div className="mini-credit-card">
                                            <h2>Credit cards Available</h2>
                                            <div className="row ">
                                                <div className="col-lg-10">
                                                    <div className="gap-2 d-flex mini-credits">
                                                        <ShimmerThumbnail height={60} width={20} />
                                                        <ShimmerThumbnail height={60} width={20} />
                                                        <ShimmerThumbnail height={60} width={20} />
                                                        <ShimmerThumbnail height={60} width={20} />
                                                        <ShimmerThumbnail height={60} width={20} />
                                                    </div>
                                                </div>
                                                <div className="col-lg-2"></div>
                                            </div>
                                        </div>

                                        <div className="mini-easy-finacing">
                                            <div
                                                className="mini-easy-finacing-head d-flex justify-content-between align-items-center">
                                                <h2>easy Financing offers with</h2>

                                            </div>
                                            <div className="row">
                                                <div className="col-lg-4 col-md-4 col-4">
                                                    <ShimmerThumbnail height={40} />
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-4">
                                                    <ShimmerThumbnail height={40} />
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-4">
                                                    <ShimmerThumbnail height={40} />
                                                </div>
                                            </div>

                                        </div>

                                        <div className="min-checkout-button">
                                            <ShimmerButton size="lg" />

                                        </div>
                                        <div className="mini-cart-button">
                                            <ShimmerButton size="lg" />
                                        </div>
                                    </div>
                                </Offcanvas.Body>
                            </Offcanvas>
                        }
                    </>
                    :

                    <Offcanvas show={open ? true : false} placement="end" onHide={() => setOpen(false)}>

                        <Offcanvas.Body >
                            <div className="header-bottom-border" >
                                <div className="offcanvas-header mini-cart-content d-flex justify-content-between align-items-center">
                                    <h5 id="offcanvasRightLabel">Your cart is empty</h5>
                                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas"
                                        aria-label="Close" onClick={() => { setOpen(false) }}></button>
                                </div>

                            </div>
                            <div className="blank-cart  offcanvas-body mini-sub-total-area" >

                            </div>
                        </Offcanvas.Body>
                    </Offcanvas>

                }</div><>

            </>
            <ConfirmationModal
                show={showPopup}
                message="Are you sure you want to remove product from the cart?"
                onConfirm={handleConfirm}
                onCancel={() => setShowPopup({ ...showPopup, isOpen: false, info: {} })}
            />
        </div>
    )
}

export default MiniCart